import React, { useState, useRef, useEffect } from "react";

import { Link, graphql, useStaticQuery } from "gatsby";

import ReleaseCoverPlayer from "./ReleaseCoverPlayer";
import ReleaseMeta from "./ReleaseMeta";

import * as styles from "./styles.module.scss";

const releasesQuery = graphql`
  query {
    allContentfulReleaseGroups {
      nodes {
        releases {
          artistName
          audio {
            file {
              url
            }
          }
          coverImage {
            gatsbyImageData
            description
          }
          externalLinks
          description
          label
          name
          type
          year
        }
        categoryId
      }
    }
  }
`;

const ReleasesBlock = ({ category, title, limit, seeMoreUrl, seeMoreText }) => {
  const [trackProgress, setTrackProgress] = useState();
  const [currentTrack, setCurrentTrack] = useState();
  const [isPlaying, setIsPlaying] = useState();

  const {
    allContentfulReleaseGroups: { nodes: releaseGroups },
  } = useStaticQuery(releasesQuery);

  const releases = releaseGroups.find(
    (rc) => rc.categoryId === category
  )?.releases;

  const audioRef = useRef();
  const intervalRef = useRef();

  const trackProgressPercentage = audioRef.current
    ? (trackProgress / audioRef.current.duration) * 100
    : 0;

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      clearInterval(intervalRef.current);
    };
  }, []);

  const startTrackProgressTimer = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (!audioRef.current.ended) {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, [1000]);
  };

  const handlePlayPause = (index) => {
    if (index === currentTrack) {
      if (isPlaying) {
        setIsPlaying(false);
        audioRef.current.pause();
      } else {
        setIsPlaying(true);
        audioRef.current.play();
        clearInterval(intervalRef.current);
      }
    } else {
      setCurrentTrack(index);
      setIsPlaying(true);
      audioRef.current && audioRef.current.pause();
      audioRef.current = new Audio(releases[index].audio.file.url);
      audioRef.current.play();
      startTrackProgressTimer();
    }
  };

  const handleScrub = (event) => {
    const progress =
      (event.clientX - event.target.getBoundingClientRect().x) /
      event.target.getBoundingClientRect().width;
    if (audioRef.current && audioRef.current.duration) {
      audioRef.current.currentTime = progress * audioRef.current.duration;
      setTrackProgress(audioRef.current.currentTime);
    }
    event.stopPropagation(); // why is this not preventing event bubbling?
  };

  return (
    <article className={styles.discography}>
      <h1>{title}</h1>

      <div className={styles.discogGrid}>
        {releases &&
          releases
            .slice(0, limit ? limit : releases.length)
            .map((release, index) => (
              <div className={styles.discogItem} key={index}>
                <ReleaseCoverPlayer
                  release={release}
                  index={index}
                  isPlaying={index === currentTrack && isPlaying}
                  isActive={index === currentTrack}
                  handlePlayPause={() => handlePlayPause(index)}
                  handleScrub={handleScrub}
                  currentTime={
                    index === currentTrack
                      ? audioRef.current?.currentTime
                      : null
                  }
                  trackProgressPercentage={
                    index === currentTrack ? trackProgressPercentage : null
                  }
                />
                <ReleaseMeta release={release} />
              </div>
            ))}
      </div>
      {seeMoreUrl && seeMoreText && (
        <div className={styles.readMore}>
          <span className={styles.linkWrapper}>
            <Link to={seeMoreUrl}>{seeMoreText}</Link>
          </span>
        </div>
      )}
    </article>
  );
};

export default ReleasesBlock;
