import React from "react";

import cx from "classnames";

import PlayButton from "../../../assets/play_arrow_white_24dp.svg";
import PauseButton from "../../../assets/pause_white_24dp.svg";

import * as styles from "./styles.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";

const PlayPauseButton = ({ isPlaying = false, className, onClick }) => {
  const Button = isPlaying ? PauseButton : PlayButton;
  return <Button className={className} onClick={onClick} />;
};

const formatPlayingTime = (timeInSeconds) =>
  `${Math.round(timeInSeconds / 60)}:${
    Math.round(timeInSeconds % 60) < 10 ? "0" : ""
  }${Math.round(timeInSeconds % 60)}`;

const ReleaseCoverPlayer = ({
  release,
  isPlaying,
  isActive,
  handlePlayPause,
  handleScrub,
  currentTime,
  trackProgressPercentage,
}) => (
  <div className={styles.releaseCoverWrapper}>
    <GatsbyImage
      imgClassName={styles.releaseCover}
      image={release.coverImage.gatsbyImageData}
      alt={release.name}
      draggable={false}
    />
    <div
      className={cx(styles.overlay, {
        [styles.active]: isActive,
      })}
    ></div>
    {release.externalLinks && (
      <div className={styles.playPauseButton}>
        <a target="_blank" href={release.externalLinks[0]}>
          <PlayPauseButton className={styles.playButton} />
        </a>
      </div>
    )}
    {/* {release.audio && (
      <>
        <div className={styles.playPauseButton}>
          <PlayPauseButton
            isPlaying={isPlaying}
            className={styles.playButton}
            onClick={handlePlayPause}
          />
        </div>
        {!!currentTime && (
          <div
            className={cx(styles.currentTime, {
              [styles.active]: isActive,
            })}
          >
            {formatPlayingTime(currentTime)}
          </div>
        )}
        <div
          className={cx(styles.playerProgress, {
            [styles.active]: isActive,
          })}
          role="progressbar"
          onMouseDown={handleScrub}
          onMouseMove={(event) => {
            if (event.buttons) handleScrub(event);
          }}
          style={
            isActive
              ? {
                  background: `linear-gradient( 90deg, white ${trackProgressPercentage}%, rgba( 255, 255, 255, 0.6) ${trackProgressPercentage}% 100% )`,
                }
              : null
          }
        ></div> */}
    {/* <MoreVert className={styles.moreVert} /> */}
    {/* </> */}
    {/* )} */}
  </div>
);

export default ReleaseCoverPlayer;
