import React from "react";

import cx from "classnames";

import * as styles from "./styles.module.scss";

const ReleaseMeta = ({ release }) => (
  <div className={styles.releaseMeta}>
    <div
      className={cx(styles.releaseName, {
        [styles.releaseAlbum]: release.type === "Album",
        [styles.releaseEP]: release.type === "EP",
        [styles.releaseSingle]: release.type === "Single",
      })}
      dangerouslySetInnerHTML={{ __html: release.name }}
    ></div>
    <div className={styles.releaseArtist}>{release.artistName}</div>
    <div className={styles.releaseYear}>
      {release.year}
      {release.label && `, ${release.label}`}
    </div>
    {release.description && (
      <div
        className={styles.releaseDesc}
        dangerouslySetInnerHTML={{ __html: release.description }}
      ></div>
    )}
  </div>
);

export default ReleaseMeta;
