import * as React from "react";
import Layout from "../../components/Layout";
import ReleasesBlock from "../../components/ReleasesBlock";

const SoundsPage = () => {
  return (
    <Layout title="sounds">
      <ReleasesBlock category="artist" title="official releases" />
      <ReleasesBlock category="remix" title="remixes" />
      <ReleasesBlock category="sideman" title="additional credits" />
    </Layout>
  );
};

export default SoundsPage;
